<template>
  <div class="mobile-menu-container">
    <!-- mobile-menu-light -->
    <div class="mobile-menu-wrapper">
      <span class="mobile-menu-close" @click="hideMobileMenu">
        <i class="icon-close"></i>
      </span>
      <!-- <form action="#" method="get" class="mobile-search" @submit.prevent="submitSearchForm">
                <label for="mobile-search" class="sr-only">Search</label>
                <input type="search" class="form-control" name="mobile-search" id="mobile-search" v-model="searchTerm"
                    placeholder="Search product ..." required />
                <button class="btn btn-primary" type="submit">
                    <i class="icon-search"></i>
                </button>
            </form> -->
      <tabs
        class="nav-pills-mobile nav-border-anim text-center"
        :data="tabsData"
      ></tabs>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="mobile-nav">
          <nav class="mobile-nav">
            <ul class="mobile-menu">
              <li class="active">
                <router-link to="/" class="sf-with-ul">{{
                  $t("home")
                }}</router-link>
              </li>
              <li v-for="(item, i) in menu" :key="i">
                <router-link
                  :to="'/' + item.url_path + '/'"
                  class="sf-with-ul"
                  >{{ $t(item.name.toLowerCase()) }}</router-link
                >
                <ul v-if="item.children.length">
                  <li v-for="(child, i) in item.children" :key="i">
                    <router-link :to="'/' + child.url_path">{{
                      child.name
                    }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <!-- <div class="tab-pane fade" id="mobile-categories">
          <nav class="mobile-cats-nav">
            <ul class="mobile-cats-menu">
              <li class="item-cats-lead">
                <router-link :to="'/shop/3cols?category=electronics'"
                  >Electronics</router-link
                >
              </li>
              <li class="item-cats-lead">
                <router-link :to="'/shop/3cols?category=gift-idea'"
                  >Gift Ideas</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=beds'"
                  >Beds</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=lighting'"
                  >Lighting</router-link
                >
              </li>
              <li>
                <router-link
                  :to="'/shop/3cols?category=sofas-and-sleeper-sofas'"
                  >Sofas & Sleeper sofas</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=storage'"
                  >Storage</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=armchairs-and-chaises'"
                  >Armchairs & Chaises</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=decoration'"
                  >Decoration</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=kitchen-cabinets'"
                  >Kitchen Cabinets</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=coffee-and-tables'"
                  >Coffee & Tables</router-link
                >
              </li>
              <li>
                <router-link :to="'/shop/3cols?category=furniture'"
                  >Outdoor Furniture</router-link
                >
              </li>
            </ul>
          </nav>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Tabs from "@/esf_weert_mobilesupplies/core/components/elements/TabsDefault";
import { mobileMenu } from "@/esf_weert_mobilesupplies/utilities/common.js";

export default {
  components: {
    Tabs,
  },
  data: function () {
    return {
      tabsData: [
        {
          id: "mobile-nav",
          title: "menu",
          active: true,
        },
        // {
        //   id: "mobile-categories",
        //   title: "category",
        // },
      ],
      searchTerm: "",
    };
  },
  computed: {
    menu() {
      return this.$store.getters["menu/getMenu"];
    },
  },
  mounted: function () {
    mobileMenu();
  },
  methods: {
    hideMobileMenu: function () {
      document.querySelector("body").classList.remove("mmenu-active");
    },
    submitSearchForm: function () {
      this.hideMobileMenu();
      this.$router.push({
        path: "/shop/3cols",
        query: {
          searchTerm: this.searchTerm,
        },
      });
    },
  },
};
</script>